// React
import React from 'react';
import {useIntl} from 'react-intl';
import T from 'prop-types';
import classnames from 'classnames';

// Styles
import styles from './styles.module.scss';
import pushAnalyticsEvent from '../../utils/push-analytics-event';

const pushPurchaseButtonAnalyticsEvent = (label) => {
  pushAnalyticsEvent({
    category: 'PurchaseButton',
    action: 'click',
    label
  });
};

const Header = ({className}) => {
  const intl = useIntl();

  return (
    <header className={classnames(className, styles.header)}>
      <h1>{intl.formatMessage({id: 'index.title'})}</h1>
      <h2>{intl.formatMessage({id: 'index.subtitle'})}</h2>

      <div className={styles['action-buttons']}>
        <a
          className={classnames('button', styles.button)}
          href="https://www.amazon.ca/-/fr/Aristote-Diasonama/dp/B08ZDW7L6V/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1613474129&sr=8-2"
          target="_blank"
          rel="noreferrer noopener"
          onClick={() => pushPurchaseButtonAnalyticsEvent('amazon')}
        >
          {intl.formatMessage({id: 'index.action-buttons.buy-paperback'})}
        </a>
        <a
          className={classnames('button', styles.button)}
          href="https://gum.co/iBoEp"
          onClick={() => pushPurchaseButtonAnalyticsEvent('gumroad')}
        >
          {intl.formatMessage({id: 'index.action-buttons.buy-pdf'})}
        </a>
      </div>
    </header>
  );
};

Header.propTypes = {
  className: T.string,
  onBuyBook: T.func.isRequired,
  onReadIntroduction: T.func.isRequired
};

export default Header;
