// Vendor
import React from 'react';
import {useIntl} from 'react-intl';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {string, object} from 'yup';
import classnames from 'classnames';

// Styles
import styles from './styles.module.scss';

// Utils
import pushAnalyticsEvent from '../../utils/push-analytics-event';

const subscriptionFormSchema = (intl) =>
  object().shape({
    email: string()
      .email(intl.formatMessage({id: 'subscribe-form.fields.email.errors.email'}))
      .required(intl.formatMessage({id: 'subscribe-form.fields.email.errors.required'}))
  });

const handleSubscribe = async ({email}, {setSubmitting, setStatus}) => {
  const {result, msg} = await addToMailchimp(email);

  setSubmitting(false);
  setStatus({success: result === 'success', msg});
  pushAnalyticsEvent({
    category: 'Newsletter',
    action: 'Subscribe',
    label: result
  });
};

const SubscribeForm = () => {
  const intl = useIntl();

  const alertTranslationKey = (showSuccess) =>
    showSuccess ? 'subscribe-form.messages.success' : 'subscribe-form.messages.failure';
  const subscribeSucceeded = (touched, isSubmitting, status) => touched && !isSubmitting && status && status.success;

  return (
    <div>
      <Formik
        initialValues={{email: '', firstName: '', lastName: ''}}
        validationSchema={subscriptionFormSchema(intl)}
        onSubmit={handleSubscribe}
      >
        {({isSubmitting, status, touched}) => (
          <div>
            <Form className={styles.form}>
              <div className={styles.field}>
                <Field name="email">
                  {({field, meta: {touched, error}}) => (
                    <input
                      type="email"
                      className={touched && error ? classnames(styles.field, styles.error) : styles.field}
                      placeholder={intl.formatMessage({id: 'subscribe-form.fields.email.placeholder'})}
                      {...field}
                    />
                  )}
                </Field>

                <ErrorMessage name="email" className={styles['error-message']} component="div" />
              </div>

              {subscribeSucceeded(touched, isSubmitting, status) ? (
                <i className={classnames('icon', styles.icon)} />
              ) : (
                <input
                  type="submit"
                  className={styles.button}
                  disabled={isSubmitting}
                  value={intl.formatMessage({id: 'subscribe-form.fields.submit-button'})}
                />
              )}
            </Form>

            {status && touched && !isSubmitting && (
              <p className={classnames(styles.message, status.success ? styles.success : styles.error)}>
                {intl.formatMessage({id: alertTranslationKey(status.success)})}
              </p>
            )}
          </div>
        )}
      </Formik>
    </div>
  );
};

export default SubscribeForm;
