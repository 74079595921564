// Vendor
import React from 'react';
import {Helmet} from 'react-helmet';
import T from 'prop-types';
import {useIntl} from 'react-intl';

// Images
import coverImage from '../../assets/images/4p-cover.jpg';
import logo from '../../assets/images/logo.png';
import bookAuthorLogo from '../../assets/images/author.jpg';
import bookCoverImage from '../../assets/images/book-mock-up.png';

// Config
import config from '../../config';

// eslint-disable-next-line complexity
const SEO = ({meta}) => {
  const intl = useIntl();

  let imageURL = coverImage;
  let imageWidth = config.siteImageWith;
  let imageHeight = config.siteImageHeight;

  let title;
  let description;

  let postURL;
  let keywords;
  if (meta) {
    title = meta.title;
    description = meta.description;
    keywords = meta.keywords ? meta.keywords.join(',') : null;
    postURL = `${config.siteURL}${meta.path}/`;
    imageURL = meta.image.src;
    imageWidth = meta.image.width;
    imageHeight = meta.image.height;
  }

  title = title || intl.formatMessage({id: 'site.title'});
  description = description || intl.formatMessage({id: 'site.description'});
  keywords = keywords || intl.formatMessage({id: 'site.keywords'});
  imageURL = `${config.siteURL}${imageURL}`;

  const blogURL = config.siteURL;
  let schemaOrgJSONLD = [
    {
      '@context': 'http://www.schema.org',
      '@type': 'HealthClub',
      '@id': `${config.siteURL}/#organization`,
      email: config.siteEmail,
      name: '4P Game Club',
      url: config.siteURL,
      logo: `${config.siteURL}/${logo}`,
      priceRange: '$',
      description: intl.formatMessage({id: 'organization.description'}),
      sameAs: [
        'https://www.facebook.com/4pgame',
        'https://www.twitter.com/4pgame',
        'https://www.instagram.com/4pgame',
        'https://www.youtube.com/channel/UCNaCuLELNHaDcrmaAiSPHrA'
      ]
    },
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      '@id': `${config.siteURL}/#website`,
      url: config.siteURL,
      name: title,
      publisher: {'@id': `${config.siteURL}#organization`},
      keywords: intl.formatMessage({id: 'site.keywords'})
    },
    {
      description,
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      '@id': `${config.siteURL}/#webpage`,
      name: title,
      url: `${config.siteURL}`,
      inLanguage: intl.locale,
      isPartOf: {'@id': `${config.siteURL}/#website`},
      image: {
        '@type': 'ImageObject',
        '@id': `${config.siteURL}/#primaryImage`,
        url: imageURL
      },
      primaryImageOfPage: {'@id': `${config.siteURL}/#primaryImage`}
    },
    {
      '@type': 'Book',
      '@id': `${config.siteURL}/#book`,
      isPartOf: `${config.siteURL}/#webpage`,
      author: {
        '@id': `${config.siteURL}/#author`,
        name: intl.formatMessage({id: 'book-author.name'})
      },
      publisher: {'@id': `${config.siteURL}/#organization`},
      bookFormat: 'http://schema.org/EBook',
      datePublished: '2020-10-25',
      image: `${config.siteURL}/${bookCoverImage}`,
      inLanguage: intl.locale,
      isbn: config.bookIsbn,
      mainEntityOfPage: {'@id': `${config.siteURL}/#webpage`},
      name: intl.formatMessage({id: 'index.title'}),
      numberOfPages: config.bookNumberOfPages,
      offers: {
        '@type': 'Offer',
        availability: 'http://schema.org/InStock',
        price: config.bookPrice,
        priceCurrency: 'CAD'
      }
    },
    {
      '@type': 'Person',
      '@id': `${config.siteURL}/#author`,
      name: intl.formatMessage({id: 'book-author.name'}),
      image: {
        '@type': 'ImageObject',
        '@id': `${config.siteURL}/#primaryImage`,
        url: `${config.siteURL}/${bookAuthorLogo}`,
        caption: intl.formatMessage({id: 'book-author.name'})
      },
      description: intl.formatMessage({id: 'book-author.description'}),
      sameAs: ['https://twitter.com/didia_aristote', 'https://www.linkedin.com/in/diasonama/', 'https://www.didia.me']
    }
  ];
  if (meta) {
    schemaOrgJSONLD = schemaOrgJSONLD.concat([
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': postURL,
              name: title,
              image: imageURL
            }
          }
        ]
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: blogURL,
        name: title,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: imageURL
        },
        description
      }
    ]);
  }

  schemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@graph': schemaOrgJSONLD
  };

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={meta ? postURL : blogURL} />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#4c96d7" />

      {/* General tags */}
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="description" content={description} />
      <meta name="image" content={imageURL} />
      <meta name="keywords" content={keywords} />

      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

      {/* OpenGraph tags */}
      <meta property="og:site_name" content={title} />
      <meta property="og:url" content={meta ? postURL : blogURL} />
      <meta property="og:type" content={meta ? 'article' : 'website'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" itemProp="image" content={imageURL} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta property="fb:app_id" content={config.siteFBAppID} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageURL} />
    </Helmet>
  );
};

SEO.propTypes = {
  meta: T.shape({
    description: T.string,
    image: T.shape({
      src: T.string,
      width: T.number,
      height: T.number
    }),
    keywords: T.arrayOf(T.string),
    path: T.string,
    title: T.string
  })
};

export default SEO;
