// Vendor
import React from 'react';
import classnames from 'classnames';

// Components
import Header from '../../Header';
import Footer from '../../Footer';
import PageBackground from '../../PageBackground';
import BookStory from '../../BookStory';
import BookNewsletter from '../../BookNewsletter';
import BookFAQ from '../../BookFAQ';
import BookAuthor from '../../BookAuthor';

// Styles
import styles from './styles.module.scss';

const IndexPage = () => {
  return (
    <div>
      <div className={styles.content}>
        <Header id="header" className={styles.header} />
        <BookStory id="story" className={classnames(styles.section, styles['section--at-position-1'])} />
        <BookNewsletter id="newsletter" className={classnames(styles.section, styles['section--at-position-3'])} />
        <BookFAQ className={classnames(styles.section, styles['section--at-position-4'])} />
        <BookAuthor className={classnames(styles.section, styles['section--at-position-5'])} />
        <Footer className={classnames(styles.section, styles['section--at-position-6'])} />
      </div>
      <PageBackground />
    </div>
  );
};

export default IndexPage;
