// Vendor
import React, {useState, useEffect} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import classnames from 'classnames';

// Styles
import styles from './styles.module.scss';

// Constants
const ANIMATION_DELAY = 6000;

const getImageClassNames = (imageIndex, currentPositionIndex, lastPositionIndex) => {
  if (imageIndex === currentPositionIndex) {
    return `${styles.visible} ${styles.top}`;
  }

  if (imageIndex === lastPositionIndex) {
    return styles.visible;
  }
};

const getImageStyle = (image) => {
  return {
    backgroundImage: `url("${image.src}")`,
    backgroundPosition: 'center'
  };
};

const PageBackground = () => {
  const data = useStaticQuery(graphql`
    query BackgroundImagesQuery {
      allImageSharp(filter: {fluid: {originalName: {regex: "/^background/"}}}) {
        edges {
          node {
            fluid(maxWidth: 1920, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  `);

  const allImages = data.allImageSharp.edges.map((edge) => edge.node.fluid);
  const numberOfImages = allImages.length;

  const [currentImagePosition, setCurrentImagePosition] = useState(0);
  const [lastImagePosition, setLastImagePosition] = useState(-1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newCurrentImagePosition = currentImagePosition + 1;

      if (newCurrentImagePosition >= numberOfImages) {
        setCurrentImagePosition(0);
      } else {
        setCurrentImagePosition(newCurrentImagePosition);
      }

      setLastImagePosition(currentImagePosition);

      setTimeout(() => {
        setLastImagePosition(-1);
      }, ANIMATION_DELAY / 2);
    }, ANIMATION_DELAY);

    return () => clearInterval(intervalId);
  }, [currentImagePosition, numberOfImages]);

  return (
    <div className={styles.wrapper}>
      {allImages.map((image, index) => (
        <div
          key={`background-image-${index}`}
          className={classnames(styles.image, getImageClassNames(index, currentImagePosition, lastImagePosition))}
          style={getImageStyle(image)}
        />
      ))}
    </div>
  );
};

export default PageBackground;
