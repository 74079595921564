// Vendor
import React from 'react';

// Components
import Layout from './layout';
import T from 'prop-types';

// Locale
import messages from '../../locales/fr.json';

const EnLayout = ({children}) => <Layout locale="fr" messages={messages} children={children} />;

EnLayout.propTypes = {
  children: T.oneOfType([T.arrayOf(T.element), T.element]).isRequired
};

export default EnLayout;
