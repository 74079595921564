// Components
import React from 'react';
import PageLayout from '../components/PageLayout/Fr';
import IndexPage from '../components/pages/IndexPage';

const Page = () => (
  <PageLayout>
    <IndexPage />
  </PageLayout>
);
export default Page;
