// Vendor
import React from 'react';
import T from 'prop-types';
import {useIntl} from 'react-intl';
import {useStaticQuery, graphql} from 'gatsby';
import classnames from 'classnames';

// Styles
import styles from './styles.module.scss';

const BookAuthor = ({className}) => {
  const intl = useIntl();
  const {imageSharp: {fluid: authorImage}} = useStaticQuery(graphql`
    query AuthorImageQuery {
      imageSharp(fluid: {originalName: {regex: "/author.jpg/"}}) {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
          presentationWidth
          presentationHeight
        }
      }
    }
  `);

  return (
    <section className={classnames(className, styles.wrapper)} style={{backgroundImage: `url(${authorImage.src})`}}>

      <h2 className={styles.title}>{intl.formatMessage({id: 'book-author.title'})}</h2>

      <h3 className={styles.author}>{intl.formatMessage({id: 'book-author.name'})}</h3>

      <p>
        {intl.formatMessage({id: 'book-author.paragraph1'})}
      </p>
      <p>{intl.formatMessage({id: 'book-author.paragraph2'})}</p>
      <p>{intl.formatMessage({id: 'book-author.paragraph3'})}</p>
    </section>
  );
};

BookAuthor.propTypes = {
  className: T.string
};

export default BookAuthor;
