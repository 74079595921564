import React from 'react';
import PageLayout from '../components/PageLayout/En';
import IndexPage from '../components/pages/IndexPage';

const Page = () => (
  <PageLayout>
    <IndexPage />
  </PageLayout>
);
export default Page;
