// Vendor
import React from 'react';
import {useIntl} from 'react-intl';
import T from 'prop-types';
import classnames from 'classnames';

// Styles
import styles from './styles.module.scss';

const bookParagraphs = [
  'book-story.paragraph1',
  'book-story.paragraph2',
  'book-story.paragraph3',
  'book-story.paragraph4'
];

const BookStory = ({id, className}) => {
  const intl = useIntl();

  return (
    <section id={id} className={classnames(className, styles.wrapper)}>
      <h2 className={styles.title}>{intl.formatMessage({id: 'book-story.title'})}</h2>

      <div className={styles.content}>
        {bookParagraphs.map((paragraphTextKey) => (
          <p key={paragraphTextKey} className={styles.text}>
            {intl.formatMessage({id: paragraphTextKey})}
          </p>
        ))}
      </div>
    </section>
  );
};

BookStory.propTypes = {
  className: T.string,
  id: T.string
};

export default BookStory;
