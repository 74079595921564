// Vendor
import React from 'react';
import T from 'prop-types';
import {useIntl} from 'react-intl';

// Styles
import styles from './styles.module.scss';

const faqData = [
  {
    questionKey: 'book-faq.question2',
    answerKey: 'book-faq.answer2'
  },
  {
    questionKey: 'book-faq.question3',
    answerKey: 'book-faq.answer3'
  }
];

const BookPurchase = ({className}) => {
  const intl = useIntl();

  return (
    <section className={className}>
      <h2 className={styles.title}>{intl.formatMessage({id: 'book-faq.title'})}</h2>

      <dl>
        {faqData.map(({questionKey, answerKey}) => [
          <dt className={styles.question} key={questionKey}>{intl.formatMessage({id: questionKey})}</dt>,
          <dd className={styles.answer} key={answerKey}>{intl.formatMessage({id: answerKey})}</dd>
        ])}
      </dl>
    </section>
  );
};

BookPurchase.propTypes = {
  className: T.string
};

export default BookPurchase;
