// Vendor
import React from 'react';
import T from 'prop-types';
import {useIntl} from 'react-intl';

// Styles
import styles from './styles.module.scss';

// Components
import SubscribeForm from '../SubscribeForm';

const BookNewsletter = ({className, id}) => {
  const intl = useIntl();

  return (
    <section id={id} className={className}>
      <h2 className={styles.title}>{intl.formatMessage({id: 'book-newsletter.title'})}</h2>
      <p>{intl.formatMessage({id: 'book-newsletter.description'})}</p>

      <SubscribeForm />
    </section>
  );
};

BookNewsletter.propTypes = {
  className: T.string,
  id: T.string
};

export default BookNewsletter;
