export default {
  bookDatePublished: '2020-10-25',
  bookIsbn: '978-2-9819084-1-4',
  bookNumberOfPages: '200',
  bookPrice: '20',
  siteEmail: 'hello@4pgame.club',
  siteFBAppID: '1219803215042307',
  siteImageHeight: 672,
  siteImageWith: 1200,
  siteURL: process.env.GATSBY_SITE_URL || 'https://www.4pgame.club'
};
