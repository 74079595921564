// Vendor
import React, {useState, useEffect} from 'react';
import T from 'prop-types';
import {IntlProvider} from 'react-intl';

// Styles
import '../../assets/styles/main.scss';

// Components
import SEO from '../../components/SEO';

const TemplateWrapper = ({locale, messages, children}) => {
  const [preloadClassName, setPreloadClassName] = useState('is-preload');

  useEffect(() => {
    const timer = setTimeout(() => {
      setPreloadClassName(null);
    }, 100);

    return () => clearTimeout(timer);
  });

  return (
    <IntlProvider locale={locale} messages={messages}>
      <div className={preloadClassName}>
        <SEO />
        {children}
      </div>
    </IntlProvider>
  );
};

TemplateWrapper.propTypes = {
  locale: T.string.isRequired,
  messages: T.object.isRequired,
  children: T.oneOfType([T.arrayOf(T.element), T.element]).isRequired
};

export default TemplateWrapper;
