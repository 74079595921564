// Vendor
import React from 'react';
import T from 'prop-types';
import classnames from 'classnames';


// Styles
import styles from './styles.module.scss';

const Footer = ({className}) => (
  <footer className={classnames(className, styles.footer)}>
    <ul className={styles.icons}>
      <li>
        <a href="https://twitter.com/didia_aristote" className="icon brands fa-twitter">
          <span className="label">Twitter</span>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/thefuture2092" className="icon brands fa-instagram">
          <span className="label">Instagram</span>
        </a>
      </li>
      <li>
        <a href="mailto:didia@4pgame.club" className="icon fa-envelope">
          <span className="label">Email</span>
        </a>
      </li>
    </ul>
    <ul className={styles.copyright}>
      <li>&copy; 4P Game club.</li>
      <li>
        Design: <a href="http://html5up.net">HTML5 UP</a>
      </li>
    </ul>
  </footer>
);

Footer.propTypes = {
  className: T.string
};

export default Footer;
